import React, { useState } from "react";
import { ethers } from "ethers";
import apriBABI from "./aprilB.json";
import BigNumber from "bignumber.js";
import matict from "./matic.json";
import usdt from "./usdt.json";
import * as s from "../../styles/globalStyles";
import {
  StyledButton,
  StyledImg,
  StyledLink,
  ResponsiveWrapper,
  StyledLogo,
  StyledRoundButton,
} from "./homestyle.js";
import "./Home.css";
import Footer from "../footer/Footer.js";
const Home = () => {
  const [selectedOption, setSelectedOption] = useState(""); // State for selected cryptocurrency
  const [bnbCost, setBnbCost] = useState(0);
  const [claimingNft, setClaimingNft] = useState(false);
  const [walletAddress, setwalletAdress] = useState(null);
  const [mintAmount, setMintAmount] = useState(1);
  const [mintedTokens, setmintedTokens] = useState(0);
  const [ready, setready] = useState(false);
  const [networkId, setnetworkId] = useState(null);
  const [royalApproved, setRoyalApproved] = useState(false);
  const [usdtApproved, setUsdtApproved] = useState(false);

  const aprilCon = "0xc36D2f5bB9f6461dB9EF237b7034027586830c7b";

  const connectMetamask = async () => {
    if (typeof window.ethereum === "undefined") {
      throw new Error("Please install MetaMask to use this dApp!");
    }

    await window.ethereum.request({ method: "eth_accounts" });
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    console.log("hi");
    const accounts = await provider.listAccounts();
    const address = accounts[0];
    const network = await provider.getNetwork();
    const networkId = network.chainId;

    if (networkId == "1") {
      const aprilContract = connectContract(aprilCon, apriBABI, provider);
      const value = await aprilContract.totalSupply();
      const bigNum = new BigNumber(value._hex);
      const decimalValue = bigNum.toString(10);
      setmintedTokens(decimalValue);
      setwalletAdress(address);
      setnetworkId(networkId);
      setready(true);
    }
  };

  const handleSelect = (value) => {
    setSelectedOption(value);
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 5) {
      newMintAmount = 5;
    }
    setMintAmount(newMintAmount);
  };

  const connectContract = (address, abi, provider) => {
    return new ethers.Contract(address, abi, provider);
  };

  return (
    <div className="homebg">
      <div className="home" id="home">
        <div className="homeleft">
          <img
            className="left-img"
            src="https://i.imgur.com/17cwv9O.png"
            alt=""
          />
          <div className="textarealeft">
            Like a butterfly effect's ethereal glance, <br></br>her eyelash
            met the chance, <br></br> Music wove through time and space,{" "}
            <br></br>Setting the world in a dancing embrace.
          </div>
        </div>

        <div className="homeright">
          <div className="rightcontain">
            <div className="textarealeftm">
              Like a butterfly effect's ethereal glance, <br></br> her
              eyelash met the chance, <br></br> Music wove through time and
              space, <br></br>Setting the world in a dancing embrace.
            </div>
            <h2 className="home-main-title">
              The Butterfly's <span style={{}}>Trace</span>
            </h2>
            <p className="home-par">
              The butterfly's trace is unseen,
              <br></br>Yet the butterfly's trace never fades.<br></br>
              It is the pull of the mysterious,<br></br>
              Luring meaning, then departing<br></br>
              Once the path becomes clear.<br></br>
              <br></br>
              It is the lightness of eternity in the everyday,<br></br>
              Longings reaching upwards,<br></br>
              And a beautiful radiance.<br></br>
              <br></br>
              It is a mole in the light, signaling<br></br>
              When it guides us to words,<br></br>
              Our inner selves the guide.<br></br>
              <br></br>
              It is like a song attempting<br></br>
              To speak, yet it suffices<br></br>
              To quote from the shadows,<br></br>
              And remains unsaid...<br></br>
              <br></br>
              The butterfly’s trace is unseen,<br></br>
              The butterfly’s trace never fades!<br></br>
              <br></br>
              <span style={{ color: "black", fontWeight: "bold" }}>
                By Mahmoud Darwich
              </span>
            </p>
            <br></br>
            <h2 className="home-short-item">
              <span style={{ color: "black", fontWeight: "bold" }}>
                {" "}
                {mintedTokens} / 5000{" "}
              </span>
              <span style={{ color: "Teal", fontWeight: "bold" }}>
                APRIL BUTTERFLY
              </span>{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>MINTED</span>
            </h2>

            {ready ? (
              <>
                <div style={{ marginLeft: "auto", marginRight: "auto" }}></div>
                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                  <StyledRoundButton
                    style={{
                      lineHeight: 0.4,
                      color: "white",
                      backgroundColor: "#008080",
                    }}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      decrementMintAmount();
                    }}
                  >
                    -
                  </StyledRoundButton>
                  <s.SpacerMedium />
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    {mintAmount}
                  </s.TextDescription>
                  <s.SpacerMedium />
                  <StyledRoundButton
                    style={{
                      lineHeight: 0.4,
                      color: "white",
                      backgroundColor: "#008080",
                    }}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      incrementMintAmount();
                    }}
                  >
                    +
                  </StyledRoundButton>
                </s.Container>
                <s.SpacerSmall />
                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                  <StyledButton
                    style={{
                      color: "white",
                      backgroundColor: "#008080",
                    }}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      // handleMint();
                    }}
                  >
                    {claimingNft ? "BUSY" : "BUY"}
                  </StyledButton>
                </s.Container>
              </>
            ) : (
              <>
                <input className="connect-txt" type="text" />
                <button
                  className="connect-txtbtn"
                  onClick={() => connectMetamask()}
                >
                  connect
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="Globals">  <a href="https://arabic.aprilbutterfly.com/">Arabic</a> </div>
    </div>
  );
};

export default Home;
