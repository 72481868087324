import logo from './logo.svg';
import './App.css';
import Home from './components/Home/Home.js'
import Footer from './components/footer/Footer.js';

function App() {
  return (
    <div className="App">
      <Home />
   
    </div>
  );
}

export default App;
